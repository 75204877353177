import {
  OPEN_POPUP,
  CLOSE_POPUP
} from './PopupConstants';

const initialState = {
  data: {},
  isVisible: false,
  templateType: ''
};

export default function popup (state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP:
      return Object.assign({}, state, {
        isVisible: true,
        data: action.data,
        templateType: action.templateType
      });
    case CLOSE_POPUP:
      return Object.assign({}, state, {
        isVisible: false,
        data: {},
        templateType: ''
      });
    default:
      return state;
  }
};
