import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { appLoad } from './AppActions';
import Loading from '../Loading/Loading';
import ErrorBox from '../ErrorBox/ErrorBox';
import Logo from '../Logo/Logo';
import Header from '../Header/Header';
import UsersList from '../UsersList/UsersList';
import Popup from '../Popup/Popup';
import isApiError from '../../helpers/is-api-error';

class App extends Component {

  componentWillMount () {
    this.props.appLoad();
  }

  render() {
    const {
      app: { loading },
      error,
      errorMsg,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="App">
        <Logo />

        {isApiError(error)
          ? (
            <ErrorBox
              error={errorMsg}
            />
          )
          : <>
              <Header />
              <UsersList />
              <Popup />
            </>
        }

      </div>
    );
  }
}

App.propTypes = {
  app: PropTypes.shape({
    loading: PropTypes.bool.isRequired
  }).isRequired,
  error: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  appLoad: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    error: state.apiData.error,
    errorMsg: state.apiData.errorMsg
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    appLoad,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
