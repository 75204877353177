import React from 'react';
import PropTypes from "prop-types";

const Close = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={props.className}>
    {props.title.length ? <title>{props.title}</title> : null}
    <path d="M13.01 4.41L11.6 3 8 6.59 4.42 3 3.01 4.41 6.59 8l-3.58 3.59L4.42 13 8 9.41 11.6 13l1.41-1.41L9.41 8z"></path>
  </svg>
);

Close.defaultProps = {
  title: '',
  className: '',
};

Close.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Close;
