import React from 'react';
import PropTypes from "prop-types";

const ArrowRight = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 30" className={props.className}>
    {props.title.length ? <title>{props.title}</title> : null}
    <g>
      <line strokeLinecap="round" strokeMiterlimit="10" x1="1" y1="29" x2="15" y2="15"></line>
      <line strokeLinecap="round" strokeMiterlimit="10" x1="15" y1="15" x2="1" y2="1"></line>
    </g>
  </svg>
);

ArrowRight.defaultProps = {
  title: '',
  className: '',
};

ArrowRight.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ArrowRight;
