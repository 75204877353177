import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import './Header.css';

import Search from '../Icons/Search';
import { fetchRequest, searchRequest } from '../../services/apiDataActions';
import { openPopup } from '../Popup/PopupActions';
import POPUP_TEMPLATE_TYPES from "../../constants/popup-template-types";

class Header extends Component {
  render() {
    const {
      onSearchRequest,
      onOpenPopup
    } = this.props;

    return (
      <header className="Header">
        <div className="container">
          <div className="Header-content">
            <h2 className="Header-content__label">
              People's list
            </h2>
            <div className="Header-content__search">
              <input
                type="text"
                className="Header-content__search-input"
                placeholder="Search ..."
                onKeyPress={e => onSearchRequest(e.key, e.target.value)}
              />
              <Search
                className="Header-content__search-svg"
              />
            </div>
            <div className="Header-content__btn">
              <button
                className="Header-content__btn-add-person"
                onClick={() => onOpenPopup(this.props, POPUP_TEMPLATE_TYPES.ADD_USER_POPUP_TEMPLATE)}
              >
                Add Person
              </button>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  onSearchRequest: PropTypes.func.isRequired,
  onOpenPopup: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  onSearchRequest (key, value) {
    if((key === 'Enter' && value.length === 0)) dispatch(fetchRequest());
    if(key === 'Enter' && value.length >= 3) dispatch(searchRequest(value));
  },
  onOpenPopup (userAdditionalInfo, templateType) {
    dispatch(openPopup(userAdditionalInfo, templateType));
  },
});

export default connect(null, mapDispatchToProps)(Header);
