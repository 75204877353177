import {
  API_FETCH_REQUEST,
  API_FETCH_SUCCESS,
  API_FETCH_FAILURE,
  API_DELETE_FETCH_REQUEST,
  API_DELETE_FETCH_SUCCESS,
  API_DELETE_FETCH_FAILURE,
  API_SEARCH_FETCH_REQUEST,
  API_SEARCH_FETCH_SUCCESS,
  API_SEARCH_FETCH_FAILURE,
  API_SEARCH_FETCH_SUCCESS_EMPTY_DATA,
  API_CREATE_NEW_USER_FETCH_REQUEST,
  API_CREATE_NEW_USER_FETCH_SUCCESS,
  API_CREATE_NEW_USER_FETCH_FAILURE,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE
} from './apiDataConstants';

import { DRAG_END } from '../components/DragAndDrop/DragAndDropConstants';

const initialState = {
  users: [],
  newRegisteredUserName: '',
  term: '',
  pagination: {},
  loading: true,
  error: false,
  errorMsg: '',
  isNewUserRegistered: false
};

export default function apiData (state = initialState, action) {
  switch (action.type) {
    case API_FETCH_REQUEST:
    case API_DELETE_FETCH_REQUEST:
    case API_SEARCH_FETCH_REQUEST:
    case API_CREATE_NEW_USER_FETCH_REQUEST:
    case API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: false,
        isNewUserRegistered: false
      });
    case API_FETCH_SUCCESS:
    case API_SEARCH_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        users: action.users,
        pagination: action.pagination
      });
    case API_DELETE_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        users: action.users
      });
    case API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false
      });
    case API_CREATE_NEW_USER_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        isNewUserRegistered: true,
        newRegisteredUserName: action.name
      });
    case API_SEARCH_FETCH_SUCCESS_EMPTY_DATA:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        users: [],
        pagination: {},
        term: action.term
      });
    case API_FETCH_FAILURE:
    case API_DELETE_FETCH_FAILURE:
    case API_SEARCH_FETCH_FAILURE:
    case API_CREATE_NEW_USER_FETCH_FAILURE:
    case API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorMsg: action.errorMsg,
        isNewUserRegistered: false
      });
    case DRAG_END:
      return Object.assign({}, state, {
        users: action.users
      });
    default:
      return state;
  }
}
