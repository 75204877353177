import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import './UsersList.css';

import User from '../User/User';
import Pagination from '../Pagination/Pagination';
import Loading from '../Loading/Loading';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import isPaginationVisible from '../../helpers/is-pagination-visible';
import isLoaderVisible from '../../helpers/is-loader-visible';
import isUsersListVisible from '../../helpers/is-users-list-visible';

class UsersList extends Component {
  render() {
    const {
      loading,
      users,
      pagination,
      term,
    } = this.props;

    const usersList = users.map((user, i) => (
      <User
        key={i}
        dataId={i}
        {...user}
      />
    ));

    return (
      <main className="Users">
        <div className="container">

          {isLoaderVisible(loading)
            ? (
              <Loading/>
            )
            : null
          }

          {isUsersListVisible(users)
            ? (
              <ul className="Users__list">
                { usersList }
              </ul>
            )
            : <NoSearchResults
              term={term}
            />
          }

          {isPaginationVisible(pagination.more_in_provider, pagination.more_items_in_collection)
            ? (
              <Pagination/>
            )
            : null
          }
        </div>
      </main>
    );
  }
}

UsersList.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.PropTypes.shape({
    limit: PropTypes.number,
    next_start: PropTypes.number,
    start: PropTypes.number,
    more_items_in_collection: PropTypes.bool
  }).isRequired,
  term: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.apiData.loading,
    users: state.apiData.users,
    pagination: state.apiData.pagination,
    term: state.apiData.term
  };
};

export default connect(mapStateToProps, null)(UsersList);
