import React from 'react';
import PropTypes from "prop-types";

const Profile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className={props.className}>
    {props.title.length ? <title>{props.title}</title> : null}
    <path d="M60,30C60,13.458,46.542,0,30,0S0,13.458,0,30c0,6.142,1.858,11.857,5.038,16.618l-0.002,0.021l0.207,0.303
	c0.18,0.263,0.374,0.512,0.562,0.768c0.076,0.104,0.151,0.209,0.229,0.313c0.238,0.316,0.483,0.626,0.732,0.931
	c0.072,0.088,0.145,0.175,0.218,0.262c1.157,1.385,2.427,2.653,3.793,3.794c0.083,0.069,0.165,0.139,0.249,0.208
	c0.298,0.243,0.599,0.481,0.906,0.712c0.124,0.094,0.25,0.185,0.375,0.277c0.292,0.214,0.584,0.426,0.884,0.629
	c0.16,0.109,0.326,0.211,0.488,0.317c0.416,0.27,0.836,0.531,1.264,0.779c0.298,0.174,0.597,0.347,0.902,0.511
	c0.184,0.099,0.372,0.191,0.558,0.286c0.324,0.166,0.651,0.327,0.982,0.481c0.167,0.077,0.334,0.153,0.502,0.227
	c0.383,0.17,0.771,0.331,1.162,0.485c0.121,0.048,0.241,0.097,0.363,0.144c1.073,0.406,2.175,0.754,3.302,1.036
	c0.046,0.012,0.093,0.021,0.139,0.032c0.498,0.122,1.001,0.231,1.509,0.328c0.135,0.026,0.27,0.049,0.405,0.073
	c0.02,0.004,0.039,0.007,0.059,0.01l-0.003,0.02l0.528,0.074c0.12,0.019,0.24,0.033,0.36,0.05l0.11,0.015
	c0.021,0.003,0.041,0.004,0.062,0.006c0.054,0.007,0.109,0.014,0.163,0.021c0.164,0.022,0.327,0.043,0.491,0.063
	c0.397,0.046,0.796,0.082,1.198,0.112c0.084,0.006,0.168,0.016,0.251,0.022c0.096,0.006,0.193,0.013,0.289,0.019
	C28.847,59.979,29.421,60,30,60s1.153-0.021,1.724-0.053c0.099-0.006,0.198-0.013,0.297-0.02c0.084-0.006,0.168-0.016,0.253-0.022
	c0.398-0.03,0.795-0.066,1.189-0.111c0.164-0.019,0.328-0.041,0.491-0.063c0.065-0.009,0.13-0.016,0.194-0.025
	c0.02-0.003,0.041-0.004,0.061-0.006l0.101-0.014c0.113-0.016,0.227-0.03,0.339-0.048l0.561-0.079l-0.003-0.021
	c0.009-0.002,0.018-0.003,0.027-0.005c0.135-0.024,0.27-0.047,0.405-0.073c0.508-0.097,1.011-0.206,1.509-0.328
	c0.046-0.011,0.093-0.021,0.139-0.032c1.127-0.282,2.229-0.63,3.302-1.036c0.122-0.046,0.243-0.096,0.365-0.144
	c0.391-0.154,0.778-0.315,1.161-0.484c0.168-0.074,0.336-0.15,0.502-0.227c0.331-0.154,0.658-0.315,0.982-0.481
	c0.187-0.095,0.374-0.188,0.558-0.286c0.305-0.164,0.603-0.337,0.902-0.511c0.428-0.249,0.849-0.509,1.264-0.779
	c0.163-0.106,0.328-0.208,0.488-0.317c0.299-0.203,0.592-0.415,0.884-0.629c0.125-0.092,0.251-0.183,0.375-0.277
	c0.306-0.231,0.608-0.469,0.906-0.712c0.084-0.069,0.166-0.139,0.249-0.208c1.366-1.142,2.636-2.409,3.794-3.795
	c0.073-0.087,0.145-0.173,0.216-0.261c0.249-0.305,0.494-0.615,0.732-0.931c0.078-0.103,0.152-0.208,0.229-0.313
	c0.188-0.256,0.382-0.505,0.562-0.768l0.207-0.303l-0.002-0.021C58.142,41.857,60,36.142,60,30z M58,30
	c0,4.972-1.309,9.642-3.591,13.694c-1.607-5.328-6.326-9.19-11.985-9.642L42.386,34h-1.075h-4.779h-1.166
	C35.164,34,35,33.836,35,33.635V32.99c0-0.183,0.149-0.303,0.276-0.352c6.439-2.421,10.455-12.464,9.613-19.488
	c-0.439-3.658-2.25-6.927-4.883-9.295C50.517,7.892,58,18.086,58,30z M52.538,46.59c-0.081,0.109-0.162,0.217-0.244,0.325
	c-0.223,0.293-0.448,0.584-0.682,0.868c-0.024,0.029-0.049,0.057-0.073,0.086c-0.808,0.972-1.681,1.888-2.611,2.743
	c-0.055,0.051-0.11,0.103-0.166,0.153c-0.277,0.251-0.561,0.495-0.848,0.735c-0.09,0.075-0.181,0.149-0.272,0.223
	c-0.279,0.227-0.56,0.45-0.847,0.666c-0.097,0.073-0.197,0.142-0.295,0.214c-0.165,0.121-0.332,0.238-0.5,0.355V48h-2v6.233
	c-0.039,0.023-0.078,0.045-0.118,0.068c-0.255,0.146-0.51,0.291-0.769,0.428c-0.177,0.094-0.357,0.185-0.537,0.276
	c-0.302,0.152-0.606,0.299-0.913,0.44c-0.15,0.069-0.299,0.138-0.45,0.204c-0.385,0.168-0.774,0.328-1.166,0.479
	c-0.081,0.031-0.16,0.065-0.241,0.095c-1,0.374-2.022,0.692-3.063,0.95c-0.075,0.019-0.151,0.034-0.226,0.052
	c-0.431,0.103-0.865,0.197-1.302,0.279c-0.102,0.019-0.205,0.037-0.308,0.055L33.162,46H34v-2.465l4.471,2.98l4.173-10.432
	c5.279,0.595,9.524,4.673,10.247,10.012C52.774,46.261,52.659,46.427,52.538,46.59z M23.482,57.226
	c-0.075-0.018-0.15-0.034-0.225-0.052c-1.041-0.259-2.064-0.576-3.064-0.95c-0.081-0.03-0.161-0.064-0.241-0.095
	c-0.392-0.151-0.781-0.311-1.165-0.479c-0.151-0.066-0.301-0.135-0.451-0.204c-0.307-0.141-0.611-0.288-0.913-0.44
	c-0.18-0.091-0.36-0.181-0.537-0.276c-0.259-0.137-0.514-0.283-0.769-0.428c-0.039-0.023-0.079-0.045-0.118-0.068V48h-2v4.958
	c-0.167-0.117-0.335-0.235-0.5-0.355c-0.098-0.072-0.198-0.141-0.295-0.214c-0.287-0.216-0.568-0.439-0.846-0.665
	c-0.092-0.074-0.183-0.149-0.274-0.224c-0.287-0.239-0.57-0.483-0.846-0.734c-0.057-0.051-0.112-0.104-0.168-0.155
	c-0.93-0.855-1.803-1.77-2.61-2.742c-0.024-0.029-0.049-0.057-0.073-0.086c-0.234-0.284-0.459-0.575-0.682-0.868
	c-0.082-0.108-0.164-0.216-0.244-0.325c-0.12-0.163-0.235-0.329-0.352-0.495c0.724-5.339,4.968-9.417,10.247-10.012l4.173,10.432
	L26,43.535V46h0.84l-1.72,11.566c-0.112-0.02-0.224-0.039-0.335-0.06C24.348,57.423,23.913,57.329,23.482,57.226z M25.491,30.791
	C20.709,29.022,17,20.85,17,15c0-0.128,0.015-0.253,0.019-0.38l0.732-0.903c1.651-1.964,4.469-2.526,7.012-1.4
	C25.785,12.771,26.874,13,28,13c2.971,0,5.64-1.615,7.028-4.184c3.182,1.045,6.022,3.015,7.943,5.498
	c0.293,6.1-3.294,14.533-8.398,16.452C33.617,31.126,33,31.999,33,32.99v0.645c0,1.146,0.82,2.103,1.904,2.319L33.198,38h-6.396
	l-1.706-2.047C26.18,35.738,27,34.78,27,33.635V33C27,32.014,26.395,31.126,25.491,30.791z M30.04,2.002c0.012,0,0.022,0,0.033,0
	c0.489,0.003,0.97,0.03,1.43,0.083c4.959,0.553,9.126,4.005,10.752,8.589c-2.115-1.842-4.708-3.26-7.497-4.027l-0.86-0.236
	l-0.333,0.826C32.644,9.522,30.46,11,28,11c-0.845,0-1.662-0.172-2.427-0.511c-2.766-1.224-5.786-0.893-8.017,0.73
	c0.613-2.026,1.72-3.882,3.261-5.42C23.271,3.35,26.53,2.002,30.04,2.002z M34,40.162L37.469,36h3.054l-2.993,7.484L34,41.132
	V40.162z M32.365,57.888c-0.27,0.023-0.541,0.049-0.809,0.065C31.042,57.981,30.525,58,30.007,58c-0.034,0-0.069-0.003-0.103-0.003
	c-0.485-0.002-0.969-0.017-1.45-0.044c-0.264-0.015-0.53-0.041-0.796-0.063c-0.186-0.016-0.371-0.031-0.556-0.051L28.862,46h2.277
	l1.786,11.837C32.739,57.856,32.552,57.872,32.365,57.888z M32,44h-4v-1.798V40h4v2.202V44z M26,40.162v0.97l-3.529,2.353L19.478,36
	h3.054L26,40.162z M19.982,3.86c-0.193,0.174-0.392,0.338-0.578,0.523C16.564,7.218,15,10.987,15,15
	c0,6.629,4.19,15.593,9.797,17.666C24.916,32.711,25,32.848,25,33v0.635C25,33.836,24.836,34,24.635,34h-1.166h-4.779h-0.545
	c-0.096,0-0.191,0.014-0.281,0.039c-5.785,0.343-10.638,4.239-12.272,9.656C3.309,39.642,2,34.972,2,30
	C2,18.09,9.478,7.9,19.982,3.86z"/>
  </svg>
);

Profile.defaultProps = {
  title: '',
  className: '',
};

Profile.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Profile;
