import {
  CHANGE_NEW_USER_NAME,
  CHANGE_NEW_USER_PHONE,
  CHANGE_NEW_USER_EMAIL,
  CHANGE_NEW_USER_ORGANIZATION_NAME,
  CHANGE_NEW_USER_ORGANIZATION_ADDRESS,
  CHANGE_NEW_USER_ORGANIZATION_ID
} from './AddUserPopupTemplateConstants';

const initialState = {
  isDisabled: true,
  profile: {
    general: {
      name: '',
      email: '',
      phone: '',
      org_id: 0
    },
    organization: {
      name: '',
      address: ''
    }
  }
};

export default function newUser (state = initialState, action) {
  switch (action.type) {
    case CHANGE_NEW_USER_NAME: {
      const general = Object.assign({}, state.profile.general, {
        name: action.name,
      });
      return Object.assign({}, state, {
        isDisabled: action.isDisabled,
        profile: Object.assign({}, state.profile, {
          general,
        })
      });
    }
    case CHANGE_NEW_USER_PHONE: {
      const general = Object.assign({}, state.profile.general, {
        phone: action.phone
      });
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          general,
        })
      });
    }
    case CHANGE_NEW_USER_EMAIL: {
      const general = Object.assign({}, state.profile.general, {
        email: action.email
      });
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          general,
        })
      });
    }
    case CHANGE_NEW_USER_ORGANIZATION_NAME: {
      const organization = Object.assign({}, state.profile.organization, {
        name: action.name
      });
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          organization,
        })
      });
    }
    case CHANGE_NEW_USER_ORGANIZATION_ADDRESS: {
      const organization = Object.assign({}, state.profile.organization, {
        address: action.address
      });
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          organization,
        })
      });
    }
    case CHANGE_NEW_USER_ORGANIZATION_ID: {
      const general = Object.assign({}, state.profile.general, {
        org_id: action.org_id
      });
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          general,
        })
      });
    }
    default:
      return state;
  }
};
