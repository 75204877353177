import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import './Popup.css';

import isPopupVisible from '../../helpers/is-popup-visible';
import { closePopup } from "./PopupActions";
import chooseCorrectTemplate from '../../helpers/choose-correct-popup-template';
import Close from '../Icons/Close';

class Popup extends Component {
  render() {
    const {
      data,
      isVisible,
      templateType,
      onClosePopup,
    } = this.props;

    const Template = chooseCorrectTemplate(templateType);

    return (
      <>
        {isPopupVisible(isVisible, data)
          ? (
            <>
              <div
                className="Popup__bg"
                onClick={() => onClosePopup()}
              >
                Popup background
              </div>
              <div className="Popup">
                <div className="Popup__body">
                  <Template
                    {...data}
                  />
                </div>
                <div className="Popup__btn">
                  <button
                    className="Popup__btn-close"
                    onClick={() => onClosePopup()}
                  >
                    Back
                    <Close
                      className="Popup__btn-close--icon"
                    />
                  </button>
                </div>
              </div>
            </>
          )
          : null
        }
      </>
    );
  }
}

Popup.propTypes = {
  data: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  templateType: PropTypes.string.isRequired,
  onClosePopup: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    data: state.popup.data,
    isVisible: state.popup.isVisible,
    templateType: state.popup.templateType
  };
};

const mapDispatchToProps = dispatch => ({
  onClosePopup () {
    dispatch(closePopup());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
