import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ErrorBox.css';

class ErrorBox extends Component {
  render() {
    const {
      error
    } = this.props;

    return (
      <div className="Error">
        <div className="container">
          <h2>{`We got an error: ${error}`}</h2>
          <p>Please try a bit later...</p>
        </div>
      </div>
    );
  }
}

ErrorBox.propTypes = {
  error: PropTypes.string.isRequired
};

export default ErrorBox;
