import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import './AddUserPopupTemplate.css'

import {
  createNewUserRequest ,
  createNewUserOrganizationRequest
} from '../../../../services/apiDataActions';
import {
  changeNewUserName,
  changeNewUserPhone,
  changeNewUserEmail,
  changeNewUserOrganizationName,
  changeNewUserOrganizationAddress
} from './AddUserPopupTemplateActions';

import isNewUserRegisteredSuccessfully from '../../../../helpers/is-new-user-registered-successfully';
import { getStateHelper } from '../../../../helpers/get-state-helper';

class AddUserPopupTemplate extends Component {
  render() {
    const {
      profile,
      isDisabled,
      isNewUserRegistered,
      newRegisteredUserName,
      newUserOrganizationData,
      onChangeNewUserName,
      onChangeNewUserPhone,
      onChangeNewUserEmail,
      onChangeNewUserOrganizationName,
      onChangeNewUserOrganizationAddress,
      onCreateNewUserRequest
    } = this.props;

    return (
      <div className="Add-user-popup">

        {isNewUserRegisteredSuccessfully(isNewUserRegistered)
          ? (
            <div className="Add-user-popup__header Add-user-popup__header--success">
              <h3 className="Add-user-popup__header-title">{`${newRegisteredUserName} has been registered successfully`}</h3>
            </div>

          )
          :
          <div className="Add-user-popup__header">
            <h3 className="Add-user-popup__header-title">Add Person</h3>
          </div>
        }

        <div className="Add-user-popup__body">
          <div className="Add-user-popup__name">
            <label
              htmlFor="name"
              className="Add-user-popup__label"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="Add-user-popup__input"
              onChange={e => onChangeNewUserName(e.target.value)}
              value={profile.general.name}
            />
          </div>
          <div className="Add-user-popup__phone">
            <label
              htmlFor="phone"
              className="Add-user-popup__label"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              className="Add-user-popup__input"
              onChange={e => onChangeNewUserPhone(e.target.value)}
              value={profile.general.phone}
            />
          </div>
          <div className="Add-user-popup__email">
            <label
              htmlFor="email"
              className="Add-user-popup__label"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="Add-user-popup__input"
              onChange={e => onChangeNewUserEmail(e.target.value)}
              value={profile.general.email}
            />
          </div>
          <div className="Add-user-popup__organization-name">
            <label
              htmlFor="organization-name"
              className="Add-user-popup__label"
            >
              Organization
            </label>
            <input
              type="text"
              id="organization-name"
              className="Add-user-popup__input"
              onChange={e => onChangeNewUserOrganizationName(e.target.value)}
              value={profile.organization.organizationName}
            />
          </div>
          <div className="Add-user-popup__organization-name">
            <label
              htmlFor="organization-address"
              className="Add-user-popup__label"
            >
              Address
            </label>
            <input
              type="text"
              id="organization-address"
              className="Add-user-popup__input"
              onChange={e => onChangeNewUserOrganizationAddress(e.target.value)}
              value={profile.organization.organizationAddress}
            />
          </div>
        </div>
        <div className="Add-user-popup__btn">
          <button //TODO the button is disabled until "name" has at least two chars
            type="submit"
            className="Add-user-popup__btn-add-user"
            disabled={isDisabled}
            onClick={() => onCreateNewUserRequest(newUserOrganizationData)}
          >
            Save
          </button>
        </div>
      </div>
    )
  }
}

AddUserPopupTemplate.propTypes = {
  profile: PropTypes.shape({
    general: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      org_id: PropTypes.number.isRequired,
    }).isRequired,
    organization: PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isNewUserRegistered: PropTypes.bool.isRequired,
  newRegisteredUserName: PropTypes.string.isRequired,
  newUserOrganizationData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  onChangeNewUserName: PropTypes.func.isRequired,
  onChangeNewUserPhone: PropTypes.func.isRequired,
  onChangeNewUserEmail: PropTypes.func.isRequired,
  onChangeNewUserOrganizationName: PropTypes.func.isRequired,
  onChangeNewUserOrganizationAddress: PropTypes.func.isRequired,
  onCreateNewUserRequest: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    profile: state.newUser.profile,
    isDisabled: state.newUser.isDisabled,
    isNewUserRegistered: state.apiData.isNewUserRegistered,
    newRegisteredUserName: state.apiData.newRegisteredUserName,
    newUserOrganizationData: state.newUser.profile.organization
  };
};

const mapDispatchToProps = (dispatch) => ({
  async onCreateNewUserRequest (newUserOrganizationData) {
    if (newUserOrganizationData.name) {
      await dispatch(createNewUserOrganizationRequest(newUserOrganizationData));
    }
    const state = await getStateHelper(dispatch);
    dispatch(createNewUserRequest(state.newUser.profile.general));
  },
  onChangeNewUserName (name) {
    dispatch(changeNewUserName(name));
  },
  onChangeNewUserPhone (phone) {
    dispatch(changeNewUserPhone(phone));
  },
  onChangeNewUserEmail (email) {
    dispatch(changeNewUserEmail(email));
  },
  onChangeNewUserOrganizationName (name) {
    dispatch(changeNewUserOrganizationName(name));
  },
  onChangeNewUserOrganizationAddress (address) {
    dispatch(changeNewUserOrganizationAddress(address));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPopupTemplate);
