import { combineReducers } from 'redux';

import app from '../components/App/AppReducer';
import apiData from '../services/apiDataReducer';
import popup from '../components/Popup/PopupReducer';
import newUser from '../components/Popup/components/AddUserPopupTemplate/AddUserPopupTemplateReducer';
import dragAndDrop from '../components/DragAndDrop/DragAndDropReducer';

const rootReducer = combineReducers({
  app,
  apiData,
  popup,
  newUser,
  dragAndDrop,
});

export default rootReducer;
