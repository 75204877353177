import {
  CHANGE_NEW_USER_NAME,
  CHANGE_NEW_USER_PHONE,
  CHANGE_NEW_USER_EMAIL,
  CHANGE_NEW_USER_ORGANIZATION_NAME,
  CHANGE_NEW_USER_ORGANIZATION_ADDRESS
} from './AddUserPopupTemplateConstants';

export function changeNewUserName (name) {
  return {
    type: CHANGE_NEW_USER_NAME,
    name: name,
    isDisabled: name.length < 3,
  };
}

export function changeNewUserPhone (phone) {
  return {
    type: CHANGE_NEW_USER_PHONE,
    phone: phone
  };
}

export function changeNewUserEmail (email) {
  return {
    type: CHANGE_NEW_USER_EMAIL,
    email: email
  };
}

export function changeNewUserOrganizationName (name) {
  return {
    type: CHANGE_NEW_USER_ORGANIZATION_NAME,
    name: name
  };
}

export function changeNewUserOrganizationAddress (address) {
  return {
    type: CHANGE_NEW_USER_ORGANIZATION_ADDRESS,
    address: address
  };
}
