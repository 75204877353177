
export default function filterData (data) {
  return data.map(user => {
    const phoneNumber = getPhoneNumber(user.phone);
    const emailAddress = getEmailAddress(user.email);
    return {
      id: user.id,
      name: user.name,
      phone: phoneNumber,
      email: emailAddress,
      picture: user.picture_id,
      organizationName: user.organization.name,
      organizationAddress: user.organization.address,
      assistant: user.organization.d90dc6049119db2a9eb0ba785dbfadaa26173c3d,
      groups: user.organization.d7ea633db47b86cdf95beff3d35bf225cb4205cd
    };
  });
}

export function getPhoneNumber (phonesArray) {
  return phonesArray.map(phone => {
    return phone.primary ? phone.value : '';
  });
}

export function getEmailAddress (emailsArray) {
  return emailsArray.map(email => {
    return email.primary ? email.value : '';
  });
}
