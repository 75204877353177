import {
  API_FETCH_FAILURE,
  API_FETCH_REQUEST,
  API_FETCH_SUCCESS,
  API_DELETE_FETCH_REQUEST,
  API_DELETE_FETCH_SUCCESS,
  API_DELETE_FETCH_FAILURE,
  API_SEARCH_FETCH_REQUEST,
  API_SEARCH_FETCH_SUCCESS,
  API_SEARCH_FETCH_FAILURE,
  API_SEARCH_FETCH_SUCCESS_EMPTY_DATA,
  API_CREATE_NEW_USER_FETCH_REQUEST,
  API_CREATE_NEW_USER_FETCH_SUCCESS,
  API_CREATE_NEW_USER_FETCH_FAILURE,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS,
  API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE
} from './apiDataConstants';
import {CHANGE_NEW_USER_ORGANIZATION_ID} from "../components/Popup/components/AddUserPopupTemplate/AddUserPopupTemplateConstants";
import PAGINATION_BTN_TYPE from '../components/Pagination/PaginationConstants';

import filterData from '../helpers/filter-data';
import filterDataAfterSearch from '../helpers/filter-data-after-search';

export const fetchRequest = (nextStartNumber, currentStartNumber, btnType) => async (dispatch) => {
  const request = {};
  let limit = 10;
  let start = currentStartNumber || 0;

  if (btnType) {
    if (btnType === PAGINATION_BTN_TYPE.NEXT) {
      start = nextStartNumber;
    } else {
      start = start - limit;
    }
  }

  dispatch({
    type: API_FETCH_REQUEST,
    payload: request
  });

  const API_URL = `https://bestcompany-54fb66.pipedrive.com/v1/persons/list?api_token=194cb17f9c75bebbfb8ccce346c716365812a446&limit=${limit}&start=${start}`;
  const results = await fetch(API_URL)
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error));

  if (results && results.success) {
    const filteredData = filterData(results.data);
    dispatch({
      type: API_FETCH_SUCCESS,
      users: filteredData,
      pagination: results.additional_data.pagination
    })
  } else {
    dispatch({
      type: API_FETCH_FAILURE,
      errorMsg: results.error
    })
  }
};

export const deleteUserRequest = (userId, users) => async (dispatch) => {
  const request = {};
  const updatedUsersArray = users.filter(user => {
    return user.id !== userId;
  });

  dispatch({
    type: API_DELETE_FETCH_REQUEST,
    payload: request
  });

  const API_URL = `https://bestcompany-54fb66.pipedrive.com/v1/persons/${userId}?api_token=194cb17f9c75bebbfb8ccce346c716365812a446`;
  const results = await fetch(API_URL, {
    method: "DELETE",
    headers: {
      'Accept': 'application/json'
    },
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error));

  if (results && results.success) {
    dispatch({
      type: API_DELETE_FETCH_SUCCESS,
      users: updatedUsersArray
    })
  } else {
    dispatch({
      type: API_DELETE_FETCH_FAILURE,
      errorMsg: results.error
    })
  }
};

export const searchRequest = (value) => async (dispatch) => {
  const request = {};
  const term = value;

  dispatch({
    type: API_SEARCH_FETCH_REQUEST,
    payload: request
  });

  const API_URL = `https://bestcompany-54fb66.pipedrive.com/v1/searchResults?term=${term}&start=0&api_token=194cb17f9c75bebbfb8ccce346c716365812a446`;
  const results = await fetch(API_URL, {
    headers: {
      'Accept': 'application/json'
    },
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error));

  if (results && results.success) {
    if (results.data) {
      const filteredDataAfterSearch = filterDataAfterSearch(results.data);
      dispatch({
        type: API_SEARCH_FETCH_SUCCESS,
        users: filteredDataAfterSearch,
        pagination: results.additional_data.pagination
      })
    } else {
      dispatch({
        type: API_SEARCH_FETCH_SUCCESS_EMPTY_DATA,
        term: value
      })
    }
  } else {
    dispatch({
      type: API_SEARCH_FETCH_FAILURE,
      errorMsg: results.error
    })
  }
};

export const createNewUserRequest = (newUserPersonalData) => async (dispatch) => {
  const request = {};
  const req = newUserPersonalData;

  dispatch({
    type: API_CREATE_NEW_USER_FETCH_REQUEST,
    payload: request
  });

  const API_URL = 'https://bestcompany-54fb66.pipedrive.com/v1/persons?api_token=194cb17f9c75bebbfb8ccce346c716365812a446';
  const results = await fetch(API_URL, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error));

  if (results && results.success) {
    dispatch({
      type: API_CREATE_NEW_USER_FETCH_SUCCESS,
      name: results.data.name
    })
  } else {
    dispatch({
      type: API_CREATE_NEW_USER_FETCH_FAILURE,
      errorMsg: results.error
    })
  }
};

export const createNewUserOrganizationRequest = (newUserOrganizationData) => async (dispatch) => {
  const request = {};
  const req = newUserOrganizationData;

  dispatch({
    type: API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST,
    payload: request
  });

  const API_URL = 'https://bestcompany-54fb66.pipedrive.com/v1/organizations?api_token=194cb17f9c75bebbfb8ccce346c716365812a446';
  const results = await fetch(API_URL, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error));

  if (results && results.success) {
    dispatch({ type: API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS });
    dispatch({
      type: CHANGE_NEW_USER_ORGANIZATION_ID,
      org_id: results.data.id
    })
  } else {
    dispatch({
      type: API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE,
      errorMsg: results.error
    })
  }
};
