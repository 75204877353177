import React from 'react';
import './Loading.css';

function Loading() {
  return (
    <div className="Loading">
      <div className="Loading__item">
        <span>Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
