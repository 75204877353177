import {
  DRAG_START,
  DRAG_END,
} from './DragAndDropConstants';

const initialState = {
  start: false,
  end: true,
};

function dragAndDrop (state = initialState, action) {
  switch (action.type) {
    case DRAG_START:
      return (
        Object.assign({}, state, {
          start: true,
          end: false
        })
      );
      case DRAG_END:
      return (
        Object.assign({}, state, {
          start: false,
          end: true
        })
      );
    default:
      return state;
  }
}

export default dragAndDrop;
