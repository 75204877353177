import {
  APP_LOAD,
  APP_LOADED,
} from './AppConstants';

const initialState = {
  loading: true,
  error: false,
};

function app (state = initialState, action) {
  switch (action.type) {
    case APP_LOAD:
      return (
        Object.assign({}, state, {
          loading: true,
        })
      );
    case APP_LOADED:
      return (
        Object.assign({}, state, {
          loading: false,
        })
      );
    default:
      return state;
  }
}

export default app;
