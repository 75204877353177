export const API_FETCH_REQUEST = 'API_FETCH_REQUEST';
export const API_FETCH_SUCCESS = 'API_FETCH_SUCCESS';
export const API_FETCH_FAILURE = 'API_FETCH_FAILURE';
export const API_DELETE_FETCH_REQUEST = 'API_DELETE_FETCH_REQUEST';
export const API_DELETE_FETCH_SUCCESS = 'API_DELETE_FETCH_SUCCESS';
export const API_DELETE_FETCH_FAILURE = 'API_DELETE_FETCH_FAILURE';
export const API_SEARCH_FETCH_REQUEST = 'API_SEARCH_FETCH_REQUEST';
export const API_SEARCH_FETCH_SUCCESS = 'API_SEARCH_FETCH_SUCCESS';
export const API_SEARCH_FETCH_FAILURE = 'API_SEARCH_FETCH_FAILURE';
export const API_SEARCH_FETCH_SUCCESS_EMPTY_DATA = 'API_SEARCH_FETCH_SUCCESS_EMPTY_DATA';
export const API_CREATE_NEW_USER_FETCH_REQUEST = 'API_CREATE_NEW_USER_FETCH_REQUEST ';
export const API_CREATE_NEW_USER_FETCH_SUCCESS = 'API_CREATE_NEW_USER_FETCH_SUCCESS ';
export const API_CREATE_NEW_USER_FETCH_FAILURE = 'API_CREATE_NEW_USER_FETCH_FAILURE ';
export const API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST = 'API_CREATE_NEW_USER_ORGANIZATION_FETCH_REQUEST ';
export const API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS = 'API_CREATE_NEW_USER_ORGANIZATION_FETCH_SUCCESS ';
export const API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE = 'API_CREATE_NEW_USER_ORGANIZATION_FETCH_FAILURE ';
