import POPUP_TEMPLATE_TYPES from '../constants/popup-template-types';

import DefaultPopupTemplate from '../components/Popup/components/DefaultPopupTemplate/DefaultPopupTemplate';
import UserPopupTemplate from '../components/Popup/components/UserPopupTemplate/UserPopupTemplate';
import AddUserPopupTemplate from '../components/Popup/components/AddUserPopupTemplate/AddUserPopupTemplate';

export default function chooseCorrectTemplate (templateType) {
  switch (templateType) {
    case POPUP_TEMPLATE_TYPES.USER_POPUP_TEMPLATE:
      return UserPopupTemplate;
    case POPUP_TEMPLATE_TYPES.ADD_USER_POPUP_TEMPLATE:
      return AddUserPopupTemplate;
    default:
      return DefaultPopupTemplate;
  }
}
