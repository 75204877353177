import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import './Logo.css';

import LogoIcon from '../Icons/Logo';
import { fetchRequest } from '../../services/apiDataActions';

class Logo extends Component {
  render() {
    const {
      onSearchRequest,
    } = this.props;

    return (
      <div className="Logo">
        <div className="container">
          <button
            className="Logo__btn"
            type="button"
            onClick={() => onSearchRequest()}
          >
            <LogoIcon
              className="Logo__btn-icon"
            />
          </button>
        </div>
      </div>
    );
  }
}

Logo.propTypes = {
  onSearchRequest: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  onSearchRequest() {
    dispatch(fetchRequest());
  }
});

export default connect(null, mapDispatchToProps)(Logo);
