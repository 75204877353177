import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import './Pagination.css';

import { fetchRequest } from '../../services/apiDataActions';
import PAGINATION_BTN_TYPE from './PaginationConstants';
import ArrowLeft from '../Icons/Arrow-left';
import ArrowRight from '../Icons/Arrow-right';

class Pagination extends Component {
  render() {
    const {
      pagination,
      isDisabledPrevBtn,
      isDisabledNextBtn,
      onFetchRequest
    } = this.props;

    return (
      <div className="Pagination">
        <button
          className="Pagination__btn"
          disabled={ isDisabledPrevBtn }
          onClick={() => onFetchRequest(pagination.next_start, pagination.start, PAGINATION_BTN_TYPE.PREV)}
        >
          <ArrowLeft
            className="Pagination__btn-svg"
          />
          Prev
        </button>
        <span
          className="Pagination__span"
        >
          _
        </span>
        <button
          className="Pagination__btn"
          disabled={ isDisabledNextBtn }
          onClick={() => onFetchRequest(pagination.next_start, pagination.start, PAGINATION_BTN_TYPE.NEXT)}
        >
          <ArrowRight
            className="Pagination__btn-svg"
          />
          Next
        </button>
      </div>
    );
  }
}

Pagination.propTypes = {
  pagination: PropTypes.PropTypes.shape({
    limit: PropTypes.number.isRequired,
    next_start: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    more_items_in_collection: PropTypes.bool.isRequired
  }).isRequired,
  isDisabledPrevBtn: PropTypes.bool.isRequired,
  isDisabledNextBtn: PropTypes.bool.isRequired,
  onFetchRequest: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    pagination: state.apiData.pagination,
    isDisabledNextBtn: !state.apiData.pagination.more_items_in_collection,
    isDisabledPrevBtn: state.apiData.pagination.start === 0
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchRequest (nextStartNumber, currentStartNumber, btnType) {
    dispatch(fetchRequest(nextStartNumber, currentStartNumber, btnType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
