import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './NoSearchResults.css';

class NoSearchResults extends Component {
  render() {
    const {
      term
    } = this.props;

    return (
      <div className="Users__empty-list">
        <h2>{`No search results for: ${term}`}</h2>
        <p>Try to search something else ...</p>
      </div>
    );
  }
}

NoSearchResults.propTypes = {
  term: PropTypes.string.isRequired
};

export default NoSearchResults;
