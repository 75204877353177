import React from 'react';
import './DefaultPopupTemplate.css';

function DefaultPopupTemplate() {
  return (
    <div>
      <p>Default template</p>
    </div>
  );
}

export default DefaultPopupTemplate;
