import React from 'react';
import PropTypes from "prop-types";

const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232 53" className={props.className}>
    {props.title.length ? <title>{props.title}</title> : null}
    <g id="Page-1" stroke="none" strokeWidth="1">
      <path d="M11.5406318,26.4522349 C11.5406318,20.1989838 14.9304644,17.9724706 18.101402,17.9724706 C21.9928689,
		17.9724706 24.6074483,21.3551759 24.6074483,26.3939653 C24.6074483,32.1411908 21.2814601,34.7081212 17.9919544,
		34.7081212 C13.5562901,34.7081212 11.5406318,30.4237701 11.5406318,26.4522349 Z M19.6640692,10.6151633 C15.0368717,
		10.6151633 12.3584479,12.7128699 11.0663593,14.1604102 C10.9113086,12.9122134 10.0965327,11.2929311 6.91343434,
		11.2929311 L0,11.2929311 L0,18.5919688 L2.83347441,18.5919688 C3.31382736,18.5919688 3.468878,18.7453099 3.468878,
		19.2298678 L3.468878,52.5600953 L11.7078433,52.5600953 L11.7078433,40.0505261 C11.7078433,39.7131756 11.7017629,
		39.3972929 11.6926422,39.1151453 C12.9786505,40.3050724 15.4381792,41.9488893 19.2779627,41.9488893 C27.3314752,
		41.9488893 32.9649818,35.5085619 32.9649818,26.2835597 C32.9649818,16.91135 27.6172548,10.6151633 19.6640692,
		10.6151633" id="Fill-1"></path>
      <path d="M44.0295169,33.3921477 L44.0295169,15.7303166 C44.0295169,12.7462983 42.6036591,11.2926244 39.6820186,
		11.2926244 L32.3186334,11.2926244 L32.3186334,18.5916621 L35.155148,18.5916621 C35.6324608,18.5916621 35.7875114,
		18.74807 35.7875114,19.2295612 L35.7875114,36.8300558 C35.7875114,39.8600764 37.1677661,41.267748 40.1258891,
		41.267748 L47.4983949,41.267748 L47.4983949,34.0331136 L44.6618803,34.0331136 C44.205849,34.0331136 44.0295169,
		33.8491043 44.0295169,33.3921477" id="Fill-4"></path>
      <path d="M63.8735664,34.7081212 C59.4500629,34.7081212 57.425284,30.4237701 57.425284,26.4522349 C57.425284,
		20.1989838 60.8151166,17.9724706 63.9890943,17.9724706 C67.8805613,17.9724706 70.4951407,21.3551759 70.4951407,
		26.3939653 C70.4951407,32.1411908 67.1721927,34.7081212 63.8735664,34.7081212 M65.5548018,10.6151633 C60.9215239,
		10.6151633 58.2491805,12.7128699 56.9510115,14.1604102 C56.799001,12.9122134 55.9872653,11.2929311 52.8041669,
		11.2929311 L45.8876924,11.2929311 L45.8876924,18.5919688 L48.7211668,18.5919688 C49.1984795,18.5919688 49.3535302,
		18.7453099 49.3535302,19.2298678 L49.3535302,52.5600953 L57.5985759,52.5600953 L57.5985759,40.0505261 C57.5985759,
		39.7131756 57.5894553,39.3972929 57.5803346,39.1151453 C58.8602625,40.3050724 61.3289118,41.9488893 65.1656551,
		41.9488893 C73.2222078,41.9488893 78.8496339,35.5085619 78.8496339,26.2835597 C78.8496339,16.91135 73.5049472,
		10.6151633 65.5548018,10.6151633" id="Fill-6"></path>
      <path d="M88.1399034,21.9571931 C89.0337247,19.0161103 91.268278,17.3477588 94.3571299,17.3477588 C96.8379401,
		17.3477588 98.7471911,19.2522557 99.0846543,21.9571931 L88.1399034,21.9571931 Z M94.3571299,10.6160834 C85.4797208,
		10.6160834 79.2716149,17.0594775 79.2716149,26.2844798 C79.2716149,35.3592077 85.9691944,41.9498093 95.1992677,
		41.9498093 C102.459286,41.9498093 106.89495,37.6133223 107.083443,37.4293129 L107.414826,37.0980961 L103.824339,
		31.0901907 L103.292303,31.5992832 C103.25582,31.6268846 99.9997571,34.6538385 95.6978619,34.6538385 C91.5692586,
		34.6538385 88.5472913,32.1114426 87.8328423,28.1245733 L107.372263,28.1245733 L107.417866,27.5786789 C107.433067,
		27.5050751 107.594198,25.7355185 107.594198,24.8706746 C107.594198,16.3449079 102.273833,10.6160834 94.3571299,
		10.6160834 L94.3571299,10.6160834 Z" id="Fill-9"></path>
      <path d="M122.868814,34.7081212 C118.949985,34.7081212 116.414451,31.3990197 116.414451,26.2835597 C116.414451,
		21.3091735 119.053352,17.9724706 122.981302,17.9724706 C127.435207,17.9724706 129.432624,22.1157479 129.432624,
		26.2283569 C129.432624,32.4846749 126.039752,34.7081212 122.868814,34.7081212 M137.562142,33.3924544 L137.562142,
		4.44471634 C137.562142,1.460698 136.118043,0.00702411186 133.15688,0.00702411186 L125.793495,0.00702411186
		L125.793495,7.3091286 L128.630009,7.3091286 C129.107322,7.3091286 129.262373,7.46246973 129.262373,7.94702769
		L129.262373,13.179027 C128.046289,12.071904 125.671886,10.6151633 121.582806,10.6151633 C113.587057,10.6151633
		108.008275,17.0585575 108.008275,26.2835597 C108.008275,35.6557694 113.352961,41.9488893 121.306147,41.9488893
		C125.851259,41.9488893 128.471919,39.8205144 129.751846,38.3361723 C129.919058,39.6303714 130.749035,41.2680547
		133.831806,41.2680547 L140.970216,41.2680547 L140.970216,34.0334203 L138.194506,34.0334203 C137.738474,34.0334203
		137.562142,33.8494109 137.562142,33.3924544" id="Fill-11"></path>
      <path d="M159.30541,10.8970043 C155.876055,10.8970043 152.720318,12.8935058 150.84755,16.0738008 L150.84755,
		15.3898993 C150.84755,12.6726946 149.360888,11.2926244 146.442288,11.2926244 L139.358602,11.2926244 L139.358602,
		18.5916621 L142.192076,18.5916621 C142.669389,18.5916621 142.82748,18.7450032 142.82748,19.2295612 L142.82748,
		41.267748 L151.066445,41.267748 L151.066445,29.273405 C151.066445,27.6939914 151.267099,26.1728474 151.656245,
		24.7651759 C152.89665,20.6280322 156.192236,19.1559574 158.858499,19.1559574 C159.804004,19.1559574 160.491091,
		19.2571626 160.503252,19.2602294 L161.190339,19.3645014 L161.190339,11.1024814 L160.688705,11.0166104 C160.664383,
		11.0104767 159.965135,10.8970043 159.30541,10.8970043" id="Fill-14"></path>
      <path d="M172.918248,33.3921477 L172.918248,15.7303166 C172.918248,12.7462983 171.498471,11.2926244 168.57683,
		11.2926244 L161.915733,11.2926244 L161.915733,18.5916621 L164.043879,18.5916621 C164.524232,18.5916621 164.676243,
		18.74807 164.676243,19.2295612 L164.676243,36.8300558 C164.676243,39.8600764 166.059537,41.267748 169.023741,
		41.267748 L176.390166,41.267748 L176.390166,34.0331136 L173.550611,34.0331136 C173.09458,34.0331136 172.918248,
		33.8491043 172.918248,33.3921477" id="Fill-16"></path>
      <path d="M204.024446,11.2932378 L199.628305,11.2932378 C197.059328,11.2932378 195.682114,12.1918168 194.867338,
		14.3968622 L189.495289,29.5316315 C189.309837,30.09286 189.136545,30.7215586 188.987575,31.3257227 C188.832524,
		30.7215586 188.647071,30.0836595 188.443377,29.522431 L183.071329,14.3937954 C182.247432,12.1642154 180.915821,
		11.2932378 178.313402,11.2932378 L173.637561,11.2932378 L173.637561,18.5922754 L174.850605,18.5922754 C175.495129,
		18.5922754 175.701863,18.8130867 175.853873,19.2179072 L184.168844,41.2683614 L193.4354,41.2683614 L201.75037,
		19.2179072 C201.902381,18.8130867 202.115195,18.5922754 202.75972,18.5922754 L204.024446,18.5922754 L204.024446,
		11.2932378 Z" id="Fill-18"></path>
      <path d="M211.996481,21.9571931 C212.899423,19.0161103 215.124856,17.3477588 218.210668,17.3477588 C220.697558,
		17.3477588 222.603769,19.2522557 222.941232,21.9571931 L211.996481,21.9571931 Z M218.210668,10.6160834 C209.336299,
		10.6160834 203.131233,17.0594775 203.131233,26.2844798 C203.131233,35.3592077 209.822732,41.9498093 219.052805,
		41.9498093 C226.315864,41.9498093 230.754568,37.6133223 230.940021,37.4293129 L231.274444,37.0980961 L227.693078,
		31.0901907 L227.145841,31.5992832 C227.118479,31.6268846 223.844174,34.6538385 219.55748,34.6538385 C215.425837,
		34.6538385 212.403869,32.1114426 211.68942,28.1245733 L231.234921,28.1245733 L231.280524,27.5786789 C231.289645,
		27.5050751 231.456856,25.7355185 231.456856,24.8706746 C231.456856,16.3449079 226.133451,10.6160834 218.210668,
		10.6160834 L218.210668,10.6160834 Z" id="Fill-20"></path>
      <polygon id="Fill-22"
               points="43.4658622 0.00917088765 36.1207183 0.00917088765 36.1207183 7.81423427 43.4658622 7.81423427"></polygon>
      <polygon id="Fill-24"
               points="172.358546 0.00917088765 165.010362 0.00917088765 165.010362 7.81423427 172.358546 7.81423427"></polygon>
    </g>
  </svg>
);

Logo.defaultProps = {
  title: '',
  className: '',
};

Logo.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
