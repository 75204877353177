import {
  OPEN_POPUP,
  CLOSE_POPUP
} from './PopupConstants';

export function openPopup (data, templateType) {
  return {
    type: OPEN_POPUP,
    data: data,
    templateType: templateType
  };
}

export function closePopup () {
  return {
    type: CLOSE_POPUP
  };
}
