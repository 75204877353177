import USER from '../constants/user-types';

export default function filterDataAfterSearch (data) {
  return data.filter(user => user.type === USER.PERSON).map(user => {
    return {
      id: user.id,
      name: user.title,
      phone: user.details.phone,
      email: user.details.email,
      picture: user.details.picture,
      organizationName: user.details.org_name,
      organizationAddress: user.details.org_address,
      assistant: '',
      groups: ''
    };
  });
}
