import {
  APP_LOAD,
  APP_LOADED,
} from './AppConstants';

import { fetchRequest } from '../../services/apiDataActions';

export const appLoad = () => async (dispatch) => {
  dispatch({ type: APP_LOAD });
  await dispatch(fetchRequest());
  dispatch({ type: APP_LOADED });
};
