import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import './UserPopupTemplate.css';

import { deleteUserRequest } from '../../../../services/apiDataActions';
import { closePopup } from "../../PopupActions";
import isProfilePictureVisible from "../../../../helpers/is-profile-picture-visible";
import ProfilePicture from '../../../Icons/Profile';

class UserPopupTemplate extends Component {
  render() {
    const {
      users,
      id,
      name,
      phone,
      email,
      picture,
      organizationName,
      organizationAddress,
      groups,
      assistant,
      onDeleteUserRequest,
    } = this.props;

    return (
      <div className="User-popup">
        <div className="User-popup__header">
          <h3 className="User-popup__header-title">Personal Information</h3>
        </div>
        <div className="User-popup__body">
          <div className="User-popup__body-primary">
            <div className="User-popup__picture">
              {isProfilePictureVisible(picture)
                ? (
                  <>
                    <img
                      className="User-popup__picture-img"
                      src={picture}
                      alt={name}
                    />
                  </>
                )
                : <ProfilePicture
                  className="User-popup__picture-svg"
                />
              }
            </div>
            <h3 className="User-popup__name">
              { name }
            </h3>
            <h3 className="User-popup__phone-number">
              { phone }
            </h3>
          </div>
          <div className="User-popup__body-additional">
            <div className="User-popup__group">
              <p className="User-popup__label">Email</p>
              <p className="User-popup__value">{ email }</p>
            </div>
            <div className="User-popup__group">
              <p className="User-popup__label">Organization</p>
              <p className="User-popup__value">{ organizationName }</p>
            </div>
            <div className="User-popup__group">
              <p className="User-popup__label">Assistant</p>
              <p className="User-popup__value">{ assistant }</p>
            </div>
            <div className="User-popup__group">
              <p className="User-popup__label">Groups</p>
              <p className="User-popup__value">{ groups }</p>
            </div>
            <div className="User-popup__group">
              <p className="User-popup__label">Location</p>
              <p className="User-popup__value">{ organizationAddress }</p>
            </div>
          </div>
        </div>
        <div className="User-popup__btn">
          <button
            className="User-popup__btn-delete"
            onClick={() => onDeleteUserRequest(id, users)}
          >
            Delete
          </button>
        </div>
      </div>
    )
  }
}

UserPopupTemplate.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  email: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired,
  organizationName: PropTypes.string,
  organizationAddress: PropTypes.string,
  groups: PropTypes.string.isRequired,
  assistant: PropTypes.string.isRequired,
  onDeleteUserRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    users: state.apiData.users
  };
};

const mapDispatchToProps = dispatch => ({
  onDeleteUserRequest (id, users) {
    dispatch(closePopup());
    dispatch(deleteUserRequest(id, users));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPopupTemplate);
