import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import './User.css';

import { openPopup } from '../Popup/PopupActions';
import {
  dragStart,
  dragOver,
  dragEnd
} from '../DragAndDrop/DragAndDropActions';
import POPUP_TEMPLATE_TYPES from '../../constants/popup-template-types';

import isProfilePictureVisible from "../../helpers/is-profile-picture-visible";
import ProfilePicture from '../Icons/Profile';
import Organization from '../Icons/Organization';

const placeholder = document.createElement("li");
placeholder.className = "placeholder";

class User extends Component {
  render() {
    const {
      dataId,
      users,
      name,
      picture,
      organizationName,
      onOpenPopup,
      onDragStart,
      onDragEnd,
    } = this.props;

    return (
      <li
        className="Users__list-item"
        data-id={dataId}
        draggable
        onDragStart={e => onDragStart(e)}
        onDragOver={e => dragOver(e, placeholder)}
        onDragEnd={e => onDragEnd(e, placeholder, users)}
      >
        <div className="User" onClick={() => onOpenPopup(this.props, POPUP_TEMPLATE_TYPES.USER_POPUP_TEMPLATE)}>
          <h3 className="User__title">{name}</h3>
          <div className="User__organization">
            < Organization
              className="User__organization-icon"
            />
            <span className="User__organization-name">{organizationName}</span>
          </div>
          <div className="User__picture">
            {isProfilePictureVisible(picture)
              ? (
                <>
                  <img
                    className="User__picture-img"
                    src={picture}
                    alt={name}
                  />
                </>
              )
              : <ProfilePicture
                className="User__picture-svg"
              />
            }
          </div>
        </div>
      </li>
    );
  }
}

User.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  organizationName: PropTypes.string,
  onOpenPopup: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    users: state.apiData.users
  };
};

const mapDispatchToProps = dispatch => ({
  onOpenPopup (userAdditionalInfo, templateType) {
    dispatch(openPopup(userAdditionalInfo, templateType));
  },
  onDragStart (e) {
    dispatch(dragStart(e));
  },
  onDragEnd (e, placeholder, users) {
    dispatch(dragEnd(e, placeholder, users));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
