import React from 'react';
import PropTypes from "prop-types";

const Organization = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={props.className}>
    {props.title.length ? <title>{props.title}</title> : null}
    <path d="M3 3h10v18H3V3zm12 4l2.5-2h1L21 7v14h-6V7zM5 5v2h2V5H5zm0 4v2h2V9H5zm0 4v2h2v-2H5zm0 4v2h2v-2H5zM9 5v2h2V5H9zm0 4v2h2V9H9zm0 4v2h2v-2H9zm0 4v2h2v-2H9zm8 0v2h2v-2h-2zm0-4v2h2v-2h-2zm0-4v2h2V9h-2z"></path>
  </svg>
);

Organization.defaultProps = {
  title: '',
  className: '',
};

Organization.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Organization;
