import {
  DRAG_START,
  DRAG_END,
} from './DragAndDropConstants';

let dragOverPosition;
let draggedItem;

export const dragStart = (e) => dispatch => {
  draggedItem = e.currentTarget;

  dispatch({
    type: DRAG_START
  });
};

export const dragOver = (e, placeholder) => {
  const dragPositionY = e.clientY;
  dragOverPosition = e.currentTarget;

  e.preventDefault();

  draggedItem.classList.add('dragged');
  draggedItem.style.top = dragPositionY + 25 + "px";
  e.currentTarget.parentElement.insertBefore(placeholder, e.currentTarget);
};


export const dragEnd = (e, placeholder, users) => dispatch => {
  const updatedUsersArray = users.slice(0);
  const draggedFrom = Number(draggedItem.dataset.id);
  const draggedTo = Number(dragOverPosition.dataset.id);

  draggedItem.classList.remove('dragged');
  draggedItem.parentElement.removeChild(placeholder);

  // update state
  updatedUsersArray.splice(draggedTo, 0, updatedUsersArray.splice(draggedFrom, 1)[0]);

  dispatch({
    type: DRAG_END,
    users: updatedUsersArray
  });
};
